import React, { useContext, useState, useEffect } from "react";
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import PrivateRoute from './PrivateRoute'
import Home from '../components/layout/Home/Home'
import Inicio from '../components/layout/Home/Inicio'
import Login from '../components/layout/Login/Login'
import ListadoUsuarios from '../components/layout/Login/ListadoUsuarios'
import Logout from '../components/layout/Login/Logout'
import AuthContextProvider from "./contexts/AuthContext.js";

// {/* Catalogos */}
import BotonesCatalogos from "./views/Catalogos/BotonesCatalogos"
import ListadoPeriodosAdmin from './views/Catalogos/PeriodosAdmin/ListadoPeriodosAdmin'
import ListadoAreas from './views/Catalogos/Areas/ListadoAreas'
import ListadoDepartamentos from './views/Catalogos/Departamentos/ListadoDepartamentos'
import PeriodosAdminCreate from './views/Catalogos/PeriodosAdmin/PeriodosAdminCreate'
import ListadoPlanteles from './views/Catalogos/Planteles/ListadoPlanteles'
import ListadoCiclos from './views/Catalogos/Ciclos/ListadoCiclos'
import ListadoProveedores from './views/Catalogos/Proveedores/ListadoProveedores'
import ProveedoresCreate from './views/Catalogos/Proveedores/ProveedoresCreate'

// {/* Articulos */}
import BotonesArticulos from "./views/Articulos/BotonesArticulos"
import ListadoColores from './views/Articulos/Colores/ListadoColores'
import ListadoTiposTelas from './views/Articulos/TiposTelas/ListadoTiposTelas'
import ListadoArticulos from './views/Articulos/Articulos/ListadoArticulos'
import ArticulosCreate from './views/Articulos/Articulos/ArticulosCreate'

// {/* Inventarios */}
import BotonesInventarios from "./views/Inventarios/BotonesInventarios"
import ListadoInventarios from './views/Inventarios/Inventarios/ListadoInventarios'
import ListadoDisponible from "./views/Inventarios/Inventarios/ListadoDisponible";
import ListadoDisponiblePendRecibir from "./views/Inventarios/Inventarios/ListadoDisponiblePendRecibir";
import ListadoSeguimiento from "./views/Inventarios/Inventarios/ListadoSeguimiento";
import ListadoDisponibleVendedor from "./views/Inventarios/Inventarios/ListadoDisponibleVendedor";
import ListadoPendRecibir from "./views/Inventarios/Inventarios/ListadoPendRecibir";
import ListadoPendRecibirProveedor from "./views/Inventarios/Inventarios/ListadoPendRecibirProveedor";
import ReporteProgramacion from "./views/Inventarios/Inventarios/ReporteProgramacion";
import ProgramacionVendedores from "./views/Inventarios/Inventarios/ProgramacionVendedores";

// {/* Compras */}
import BotonesCompras from "./views/Compras/BotonesCompras"
import ListadoOrdenesCompra from './views/Compras/OrdenesCompra/ListadoOrdenesCompra'
import OrdenesCompraCreate from './views/Compras/OrdenesCompra/OrdenesCompraCreate'
import ListadoCompras from "./views/Compras/Compras/ListadoCompras";
import ComprasCreate from "./views/Compras/Compras/ComprasCreate";
import ComprasContenedorCreate from "./views/Compras/Compras/ComprasContenedorCreate";
import ListadoDevolucionesProveedores from "./views/Compras/DevolucionesProveedores/ListadoDevolucionesProveedores";
import DevolucionesProveedoresCreate from "./views/Compras/DevolucionesProveedores/DevolucionesProveedoresCreate";

// {/* Compras Int */}
import BotonesComprasInt from "./views/ComprasInt/BotonesComprasInt"
import ListadoOrdenesCompraInt from './views/ComprasInt/OrdenesCompraInt/ListadoOrdenesCompraInt'
import OrdenesCompraCreateInt from './views/ComprasInt/OrdenesCompraInt/OrdenesCompraCreateInt'
import ListadoComprasInt from "./views/ComprasInt/ComprasInt/ListadoComprasInt";
import ComprasCreateInt from "./views/ComprasInt/ComprasInt/ComprasCreateInt";
import ComprasContenedorCreateInt from "./views/ComprasInt/ComprasInt/ComprasContenedorCreateInt";
import ListadoEmbarques from './views/ComprasInt/Embarques/ListadoEmbarques'
import EmbarquesCreate from './views/ComprasInt/Embarques/EmbarquesCreate'
import ListadoPendEmbarcar from "./views/ComprasInt/Embarques/ListadoPendEmbarcar";
import ListadoPendRecibirInt from "./views/ComprasInt/ComprasInt/ListadoPendRecibirInt";
import ListadoConceptosGastosEmbarques from "./views/ComprasInt/ConceptosGastosEmbarques/ListadoConceptosGastosEmbarques";


// {/* Ventas */}
import BotonesVentas from "./views/Ventas/BotonesVentas"
import ListadoPedidos from './views/Ventas/Pedidos/ListadoPedidos'
import PedidosCreate from './views/Ventas/Pedidos/PedidosCreate'
import ListadoSurtidos from './views/Ventas/Surtidos/ListadoSurtidos'
// import SurtidosCreate from './views/Ventas/Surtidos/SurtidosCreate'
import ListadoDevoluciones from './views/Ventas/Devoluciones/ListadoDevoluciones'
import DevolucionesCreate from './views/Ventas/Devoluciones/DevolucionesCreate'
import ListadoApartados from './views/Ventas/Apartados/ListadoApartados'
import ApartadosCreate from './views/Ventas/Apartados/ApartadosCreate'
import DevolucionesSinCreate from "./views/Ventas/Devoluciones/DevolucionesSinCreate";

// {/* Admin */}
import BotonesConfigAdmin from "./views/ConfigAdmin/BotonesConfigAdmin"
import ListadoPresupuestos from './views/ConfigAdmin/Presupuestos/ListadoPresupuestos'
import PresupuestosCreate from './views/ConfigAdmin/Presupuestos/PresupuestosCreate'
import ListadoGastos from './views/ConfigAdmin/Gastos/ListadoGastos'
import GastosCreate from './views/ConfigAdmin/Gastos/GastosCreate'
import AnticiposCreate from "./views/Admin/Anticipos/AnticiposCreate";
import ListadoAnticipos from "./views/Admin/Anticipos/ListadoAnticipos";

// Tareas
import ListadoTareas from "./views/Tareas/ListadoTareas"
import TareasCreate from "./views/Tareas/TareasCreate"

//Nominas
import BotonesNominas from './views/Empleados/BotonesNominas'
import ListadoNominas from "./views/Empleados/Nominas/ListadoNominas"
import ListadoColaboradores from "./views/Empleados/Colaboradores/ListadoColaboradores"
import ListadoFaltasColaboradores from './views/Empleados/Faltas/ListadoFaltasColaboradores'
import FaltasColaboradoresCreate from './views/Empleados/Faltas/FaltasColaboradoresCreate'
import ListadoPuestos from "./views/Empleados/Puestos/ListadoPuestos"
import NominasCreate from "./views/Empleados/Nominas/NominasCreate"
import ColaboradoresCreate from "./views/Empleados/Colaboradores/ColaboradoresCreate"

// Clientes
import BotonesClientes from './views/Clientes/BotonesClientes'
import BotonesCatalogosFacturas from './views/Clientes/Facturas/Catalogos/BotonesCatalogosFacturas'
import ListadoClientes from "./views/Clientes/Clientes/ListadoClientes"
import ClientesCreate from "./views/Clientes/Clientes/ClientesCreate"
import FacturasCreate from "./views/Clientes/Facturas/FacturasCreate"
import ListadoFacturas from "./views/Clientes/Facturas/ListadoFacturas"
import ListadoDetalleFacturas from "./views/Clientes/Facturas/ListadoDetalleFacturas"
import ListadoGrupos from './views/Clientes/Facturas/Catalogos/Grupos/ListadoGrupos'
import AbonosFacturasCreate from "./views/Clientes/Facturas/AbonosFacturasCreate"
import ListadoAbonosFacturas from './views/Clientes/Facturas/ListadoAbonosFacturas'
import ListadoCobranza from "./views/Clientes/Facturas/ListadoCobranza"

//Reportes
import BotonesReportes from './views/Reportes/BotonesReportes'
import GraficaVentasMensual from "./views/Reportes/GraficaVentasMensual";
import TablaVentas from "./views/Reportes/TablaVentas";
import TablaDevoluciones from "./views/Reportes/TablaDevoluciones";
import GraficaVentasMensualComparativa from "./views/Reportes/GraficaVentasMensualComparativa";
import GraficaVentasMensualCliente from "./views/Reportes/GraficaVentasMensualCliente";
import GraficaVentasMensualVendedor from "./views/Reportes/GraficaVentasMensualVendedor";
import GraficaVentasVendedor from "./views/Reportes/GraficaVentasVendedor";
import GraficaVentasDesgloce from "./views/Reportes/GraficaVentasDesgloce";
import GraficaVentasEstado from "./views/Reportes/GraficaVentasEstado";
import CorteDiario from "./views/Reportes/CorteDiario";
import UtilidadVentas from "./views/Reportes/UtilidadVentas";

// Dashboards
import BotonesDashboards from "./views/Reportes/BotonesDashboards";
import DashboardInventarios from "./views/Reportes/DashboardInventarios";
import DashboardCXP from "./views/Reportes/DashboardCXP";
import DashboardCobranza from "./views/Reportes/DashboardCobranza";
import DashboardVentas from "./views/Reportes/DashboardVentas";
import DashboardCompras from "./views/Reportes/DashboardCompras";

// Edo Cta Clientes
import EdoCtaVistaClientes from './views/Clientes/Clientes/LoginCliente/EdoCtaVistaCliente'

// Logs
import Logs from './views/Logs/ListadoLogs'
// Config
import Configuraciones from './views/Configuracion/Configuraciones'

// Admin
import BotonesAdmin from "./views/Admin/BotonesAdmin";
import ListadoBancos from "./views/Admin/Bancos/ListadoBancos";
import AbonosCreate from './views/Admin/Abonos/AbonosCreate'
import ListadoAbonos from './views/Admin/Abonos/ListadoAbonos'
import EstadoCuenta from "./views/Admin/EstadoCuenta/EstadoCuenta";
import ListadoVentas from "./views/Admin/Ventas/ListadoVentas";
import NotasCargoCreate from './views/Admin/NotasCargo/NotasCargoCreate'
import ListadoNotasCargo from './views/Admin/NotasCargo/ListadoNotasCargo'
import ListadoDevolucionesAdmin from './views/Admin/DevolucionesAdmin/ListadoDevolucionesAdmin'
import RepartoUtilidades from "./views/Admin/RepartoUtilidades";

// CXP
import BotonesCXP from "./views/CXP/BotonesCXP";
import EstadoCuentaCXP from "./views/CXP/EstadoCuentaCXP/EstadoCuentaCXP";
import ListadoComprasCXP from "./views/CXP/ComprasCXP/ListadoComprasCXP";
import ListadoComprasCXPInter from "./views/CXP/ComprasCXP/ListadoComprasCXPInter";
import AbonosProveedoresCreate from './views/CXP/AbonosProveedores/AbonosProveedoresCreate'
import ListadoAbonosProveedores from './views/CXP/AbonosProveedores/ListadoAbonosProveedores'
import ListadoDevolucionesProveedoresAdmin from './views/CXP/DevolucionesProveedoresAdmin/ListadoDevolucionesProveedoresAdmin'
import ListadoUtilidad from "./views/Admin/Ventas/ListadoUtilidad";

// Retiros
import BotonesRetiros from "./views/Retiros/BotonesRetiros.jsx";
import ListadoSocios from "./views/Retiros/Socios/ListadoSocios.jsx";
import ListadoRetiros from "./views/Retiros/Retiros/ListadoRetiros.jsx";
import RetirosCreate from "./views/Retiros/Retiros/RetirosCreate.jsx";
import ListadoPorRetirar from "./views/Retiros/PorRetirar/ListadoPorRetirar.jsx";
import PorRetirarCreate from "./views/Retiros/PorRetirar/PorRetirarCreate.jsx";
import ListadoSociedades from "./views/Retiros/Sociedades/ListadoSociedades.jsx";
import SociedadesCreate from "./views/Retiros/Sociedades/SociedadesCreate.jsx";

function Routes() {
    return (
        <AuthContextProvider>
            <BrowserRouter forceRefresh={true}>
            <Switch>
                <Route exact path ="/" component={Home} />
                <Route exact path ="/Inicio" component={Inicio} />
                <Route exact path ="/Login" component={Login} />
                <Route exact path ="/Logout" component={Logout} />
                <Route exact path ="/MenuUsuarios" component={ListadoUsuarios} />
                {/* Catalogos */}
                <Route exact path ="/MenuCatalogos" component={BotonesCatalogos} />
                <Route exact path ="/Planteles" component={ListadoPlanteles} />
                <Route exact path ="/Areas" component={ListadoAreas} />
                <Route exact path ="/Ciclos" component={ListadoCiclos} />
                <Route exact path ="/PeriodosAdmin" component={ListadoPeriodosAdmin} />
                <Route exact path ="/Departamentos" component={ListadoDepartamentos} />
                <Route exact path ="/PeriodosAdminCreate" component={PeriodosAdminCreate} />
                <Route exact path ="/Proveedores" component={ListadoProveedores} />
                <Route exact path ="/ProveedoresCreate" component={ProveedoresCreate} />
                {/* Articulos */}
                <Route exact path ="/MenuArticulos" component={BotonesArticulos} />
                <Route exact path ="/Colores" component={ListadoColores} />
                <Route exact path ="/TiposTelas" component={ListadoTiposTelas} />
                <Route exact path ="/ListadoArticulos" component={ListadoArticulos} />
                <Route exact path ="/ArticulosCreate" component={ArticulosCreate} />
                {/* Inventarios */}
                <Route exact path ="/MenuInventarios" component={BotonesInventarios} />
                <Route exact path ="/ListadoInventarios" component={ListadoInventarios} />
                <Route exact path ="/ListadoDisponible" component={ListadoDisponible} />
                <Route exact path ="/ListadoDisponiblePendRecibir" component={ListadoDisponiblePendRecibir} />
                <Route exact path ="/ListadoDisponibleVendedor" component={ListadoDisponibleVendedor} />
                <Route exact path ="/ListadoSeguimiento" component={ListadoSeguimiento} />
                <Route exact path ="/ListadoPendRecibir" component={ListadoPendRecibir} />
                <Route exact path ="/ListadoPendRecibirProveedor" component={ListadoPendRecibirProveedor} />
                <Route exact path ="/ReporteProgramacion" component={ReporteProgramacion} />
                <Route exact path ="/ProgramacionVendedores" component={ProgramacionVendedores} />
                {/* Compras */}
                <Route exact path ="/MenuCompras" component={BotonesCompras} />
                <Route exact path ="/ListadoOrdenesCompra" component={ListadoOrdenesCompra} />
                <Route exact path ="/OrdenesCompraCreate" component={OrdenesCompraCreate} />
                <Route exact path ="/ListadoCompras" component={ListadoCompras} />
                <Route exact path ="/ComprasCreate" component={ComprasCreate} />
                <Route exact path ="/ComprasContenedorCreate" component={ComprasContenedorCreate} />
                <Route exact path ="/ListadoDevolucionesProveedores" component={ListadoDevolucionesProveedores} />
                <Route exact path ="/DevolucionesProveedoresCreate" component={DevolucionesProveedoresCreate} />
                {/* Compras Int*/}
                <Route exact path ="/MenuComprasInt" component={BotonesComprasInt} />
                <Route exact path ="/ListadoOrdenesCompraInt" component={ListadoOrdenesCompraInt} />
                <Route exact path ="/OrdenesCompraCreateInt" component={OrdenesCompraCreateInt} />
                <Route exact path ="/ListadoComprasInt" component={ListadoComprasInt} />
                <Route exact path ="/ComprasCreateInt" component={ComprasCreateInt} />
                <Route exact path ="/ComprasContenedorCreateInt" component={ComprasContenedorCreateInt} />
                <Route exact path ="/ListadoEmbarques" component={ListadoEmbarques} />
                <Route exact path ="/EmbarquesCreate" component={EmbarquesCreate} />
                <Route exact path ="/ListadoPendEmbarcar" component={ListadoPendEmbarcar} /> 
                <Route exact path ="/ListadoPendRecibirInt" component={ListadoPendRecibirInt} />  
                <Route exact path ="/ConceptosGastosEmbarques" component={ListadoConceptosGastosEmbarques} />  
               
                {/* Ventas */}
                <Route exact path ="/MenuVentas" component={BotonesVentas} />
                <Route exact path ="/ListadoPedidos" component={ListadoPedidos} />
                <Route exact path ="/PedidosCreate" component={PedidosCreate} />
                <Route exact path ="/ListadoSurtidos" component={ListadoSurtidos} />
                {/* <Route exact path ="/SurtidosCreate" component={SurtidosCreate} /> */}
                <Route exact path ="/ListadoDevoluciones" component={ListadoDevoluciones} />
                <Route exact path ="/DevolucionesCreate" component={DevolucionesCreate} />
                <Route exact path ="/ListadoApartados" component={ListadoApartados} />
                <Route exact path ="/ApartadosCreate" component={ApartadosCreate} />
                <Route exact path ="/DevolucionesSinCreate" component={DevolucionesSinCreate} />
                {/* Admin */}
                <Route exact path ="/MenuGastos" component={BotonesConfigAdmin} />
                <Route exact path ="/Presupuestos" component={ListadoPresupuestos} />
                <Route exact path ="/PresupuestosCreate" component={PresupuestosCreate} />
                <Route exact path ="/MenuNominas" component={BotonesNominas} />
                <Route exact path ="/Nominas" component={ListadoNominas} />
                <Route exact path ="/Empleados" component={ListadoColaboradores} />
                <Route exact path ="/EmpleadosCreate" component={ColaboradoresCreate} />
                <Route exact path ="/FaltasColaboradores" component={ListadoFaltasColaboradores} />
                <Route exact path ="/FaltasColaboradoresCreate" component={FaltasColaboradoresCreate} />
                <Route exact path ="/NominasCreate" component={NominasCreate} />
                <Route exact path ="/Puestos" component={ListadoPuestos} />
                <Route exact path ="/Gastos" component={ListadoGastos} />
                <Route exact path ="/GastosCreate" component={GastosCreate} />
                <Route exact path ="/AnticiposCreate" component={AnticiposCreate} />
                <Route exact path ="/ListadoAnticipos" component={ListadoAnticipos} />
                {/* Tareas */}
                <Route exact path ="/ListadoTareas" component={ListadoTareas} />
                <Route exact path ="/TareasCreate" component={TareasCreate} />
                {/* Clientes */}
                <Route exact path ="/MenuClientes" component={BotonesClientes} />
                <Route exact path ="/CatalogosFacturas" component={BotonesCatalogosFacturas} />
                <Route exact path ="/Clientes" component={ListadoClientes} />
                <Route exact path ="/ClientesCreate" component={ClientesCreate} />
                <Route exact path ="/NotasCreate" component={FacturasCreate} />
                <Route exact path ="/EdoCtaClientes" component={ListadoFacturas} />
                <Route exact path ="/DetalleNotas" component={ListadoDetalleFacturas} />
                <Route exact path ="/Zonas" component={ListadoGrupos} />
                <Route exact path ="/AbonosNotasCreate" component={AbonosFacturasCreate} />
                <Route exact path ="/AbonosClientes" component={ListadoAbonosFacturas} />
                <Route exact path ="/ListadoCobranza" component={ListadoCobranza} />
                {/* Reportes */}
                <Route exact path ="/MenuReportes" component={BotonesReportes} />
                <Route exact path ="/GraficaVentasMensual" component={GraficaVentasMensual} />
                <Route exact path ="/TablaVentas" component={TablaVentas} />
                <Route exact path ="/TablaDevoluciones" component={TablaDevoluciones} />
                <Route exact path ="/GraficaVentasVendedor" component={GraficaVentasVendedor} />
                <Route exact path ="/GraficaVentasMensualComparativa" component={GraficaVentasMensualComparativa} />
                <Route exact path ="/GraficaVentasMensualCliente" component={GraficaVentasMensualCliente} />
                <Route exact path ="/GraficaVentasMensualVendedor" component={GraficaVentasMensualVendedor} />
                <Route exact path ="/GraficaVentasDesgloce" component={GraficaVentasDesgloce} />
                <Route exact path ="/GraficaVentasEstado" component={GraficaVentasEstado} />
                <Route exact path ="/CorteDiario" component={CorteDiario} />
                <Route exact path ="/UtilidadVentas" component={UtilidadVentas} />
                 {/* Dashboards */}
                 <Route exact path ="/Dashboards" component={BotonesDashboards} />
                <Route exact path ="/DashboardCXP" component={DashboardCXP} />
                <Route exact path ="/DashboardCobranza" component={DashboardCobranza} />
                <Route exact path ="/DashboardInventarios" component={DashboardInventarios} />
                <Route exact path ="/DashboardVentas" component={DashboardVentas} />
                <Route exact path ="/DashboardCompras" component={DashboardCompras} />
                {/* Edo Cta Clientes  */}
                <Route exact path ="/EdoCtaVistaClientes" component={EdoCtaVistaClientes} />
                {/* Logs  */}
                <Route exact path ="/MenuLogs" component={Logs} />
                {/* Config  */}
                <Route exact path ="/MenuConfiguraciones" component={Configuraciones} />
                {/* Admin */}
                <Route exact path ="/MenuAdmin" component={BotonesAdmin} />
                <Route exact path ="/Bancos" component={ListadoBancos} />
                <Route exact path ="/AbonosCreate" component={AbonosCreate} />
                <Route exact path ="/ListadoAbonos" component={ListadoAbonos} />
                <Route exact path ="/EstadoCuenta" component={EstadoCuenta} />
                <Route exact path ="/ListadoVentas" component={ListadoVentas} />
                <Route exact path ="/ListadoUtilidad" component={ListadoUtilidad} />
                <Route exact path ="/NotasCargoCreate" component={NotasCargoCreate} />
                <Route exact path ="/ListadoNotasCargo" component={ListadoNotasCargo} />
                <Route exact path ="/ListadoDevolucionesAdmin" component={ListadoDevolucionesAdmin} />
                <Route exact path ="/RepartoUtilidades" component={RepartoUtilidades} />
                {/* CXP */}
                <Route exact path ="/MenuCXP" component={BotonesCXP} />
                <Route exact path ="/EstadoCuentaCXP" component={EstadoCuentaCXP} />
                <Route exact path ="/ListadoComprasCXP" component={ListadoComprasCXP} />
                <Route exact path ="/ListadoComprasCXPInter" component={ListadoComprasCXPInter} />
                <Route exact path ="/AbonosProveedoresCreate" component={AbonosProveedoresCreate} />
                <Route exact path ="/ListadoAbonosProveedores" component={ListadoAbonosProveedores} />
                <Route exact path ="/ListadoDevolucionesProveedoresAdmin" component={ListadoDevolucionesProveedoresAdmin} />
            
                {/* Retiros */}
                <Route exact path ="/MenuRetiros" component={BotonesRetiros} />
                <Route exact path ="/Socios" component={ListadoSocios} />
                <Route exact path ="/Retiros" component={ListadoRetiros} />
                <Route exact path ="/RetirosCreate" component={RetirosCreate} />
                <Route exact path ="/PorRetirar" component={ListadoPorRetirar} />
                <Route exact path ="/PorRetirarCreate" component={PorRetirarCreate} />
                <Route exact path ="/Sociedades" component={ListadoSociedades} />
                <Route exact path ="/SociedadesCreate" component={SociedadesCreate} />
            </Switch>
            </BrowserRouter>
            </AuthContextProvider>

    )
}

export default Routes
