import React, { useState, useEffect, useContext, useMemo } from "react";
import { AuthContext } from "../../contexts/AuthContext";
import Header from "../../layout/Header/Header";
// import Footer from "../layout/Footer/Footer";
import {
  Button,
  Row,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
  ButtonGroup,
  Col,
  Spinner
} from "reactstrap";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import autoTable from "jspdf-autotable";
import ExportJsonExcel from "js-export-excel";
import { encode } from "base64-arraybuffer";
import axios from "axios";
import { TableHeader, Pagination, Search } from "../../DataTable";
import useFullPageLoader from "../../hooks/useFullPageLoader";
import moment from "moment";

let date = new Date();
  let hoy = moment(date).format("YYYY-MM-DD");

function RepartoUtilidades() {
  const { user } = useContext(AuthContext);
  const URL_PARTICIPACIONES = process.env.REACT_APP_URL_PARTICIPACIONES;
  const URL_SOCIEDADES = process.env.REACT_APP_URL_SOCIEDADES;
  const URL_SURTIDOS = process.env.REACT_APP_URL_SURTIDOS;
  const URL_ARTICULOS_SURTIDOS = process.env.REACT_APP_URL_ARTICULOS_SURTIDOS;
  const URL_POR_RETIRAR = process.env.REACT_APP_URL_POR_RETIRAR;
  const URL_POR_RETIRAR_SOCIOS = process.env.REACT_APP_URL_POR_RETIRAR_SOCIOS;


  const [participaciones, setParticipaciones] = useState([]);
  const [sociedades, setSociedades] = useState([]);
  const [selectedSociedad, setSelectedSociedad] = useState("");

  const [vista, setVista] = useState("Ventas");
  const [commentsSociedad, setCommentsSociedad] = useState([]);
  const [totalUtilidad, setTotalUtilidad] = useState(0);

  const [modalProgreso, setModalProgreso] = useState(false);
  const toggleProgreso = () => setModalProgreso(!modalProgreso);

  //--- Pagination
  const [comments, setComments] = useState([]);
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [sorting, setSorting] = useState({ field: "", order: "" });

  const [totalTotalCosto, setTotalTotalCosto] = useState(0);
  const [totalTotalVenta, setTotalTotalVenta] = useState(0);
  const [totalTotalGastos, setTotalTotalGastos] = useState(0);
  const [totalTotalSaldo, setTotalTotalSaldo] = useState(0);
  const [totalTotalFlete, setTotalTotalFlete] = useState(0);
  const [totalTotalUtilidad, setTotalTotalUtilidad] = useState(0);

  const ITEMS_PER_PAGE = 50;

  useMemo(() => {
    if(selectedSociedad != ""){

      axios
      .get(`${URL_ARTICULOS_SURTIDOS}Sociedades/${selectedSociedad}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allSurtidos = response.data;
        setComments(allSurtidos);
      })
      .catch((err) => {
        console.log(err);
      });


      axios
      .get(`${URL_PARTICIPACIONES}Sociedad/${selectedSociedad}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allParticipaciones = response.data;
        setParticipaciones(allParticipaciones);
      })
      .catch((err) => {
        console.log(err);
      });
    }
      
      axios
        .get(URL_SOCIEDADES, {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        })
        .then((response) => {
          let allSociedades = response.data;
          setSociedades(allSociedades);
        })
        .catch((err) => {
          console.log(err);
        });
  }, [selectedSociedad]);

  const headers = [
    { name: "Fecha", field: "fecha", sortable: true },
    { name: "Surtido", field: "numeroSurtido", sortable: true },
    { name: "Sociedad", field: "sociedad", sortable: true },
    { name: "Cliente", field: "cliente", sortable: true },
    { name: "Costo", field: "costo", sortable: true },
    { name: "Venta", field: "venta", sortable: true },
    { name: "Gastos", field: "gastos", sortable: true },
    { name: "Flete", field: "flete", sortable: true },
    { name: "Utilidad", field: "utilidad", sortable: true },
    { name: "Saldo", field: "saldo", sortable: true },
    { name: "Porcentaje", field: "porcentaje", sortable: false },
    { name: "Pagar", field: "pagar", sortable: false },
  ];

  const commentsData = useMemo(() => {
    let computedComments = comments;

    if (search) {
      computedComments = computedComments.filter(
        (comment) =>
          comment.cliente.toLowerCase().includes(search.toLowerCase()) ||
          comment.sociedad.toLowerCase().includes(search.toLowerCase()) ||
          comment.numeroSurtido.toString().includes(search) ||
          comment.costo.toString().includes(search) ||
          comment.venta.toString().includes(search) ||
          comment.gastos.toString().includes(search) ||
          comment.saldo.toString().includes(search) ||
          comment.flete.toString().includes(search) ||
          comment.utilidad.toString().includes(search)
      );
    }

    if (selectedSociedad) {
      computedComments = computedComments.filter((e) =>
        e.idSociedad.includes(selectedSociedad)
      );
    }

    let cos = computedComments.map((c) => parseFloat(c.costo));
    let TCos = cos.reduce((t, costo, index) => t + costo, 0);
    setTotalTotalCosto(TCos);

    let ven = computedComments.map((c) => parseFloat(c.venta));
    let TVen = ven.reduce((t, venta, index) => t + venta, 0);
    setTotalTotalVenta(TVen);

    let gas = computedComments.map((c) => parseFloat(c.gastos));
    let TGas = gas.reduce((t, gastos, index) => t + gastos, 0);
    setTotalTotalGastos(TGas);

    let sal = computedComments.map((c) => parseFloat(c.saldo));
    let TSal = sal.reduce((t, saldo, index) => t + saldo, 0);
    setTotalTotalSaldo(TSal);

    let fle = computedComments.map((c) => parseFloat(c.flete));
    let TFle = fle.reduce((t, flete, index) => t + flete, 0);
    setTotalTotalFlete(TFle);

    let uti = computedComments.map((c) => parseFloat(c.utilidad));
    let TUti = uti.reduce((t, utilidad, index) => t + utilidad, 0);
    setTotalTotalUtilidad(TUti);

    let mts = comments.filter((e) => e.select == true);
    let totalMts = mts.map((c) => parseFloat(c.utilidad));
    let TM = totalMts.reduce((t, total, index) => t + total, 0);
  setTotalUtilidad(TM)

    setTotalItems(computedComments.length);

    //Sorting comments
    if (
      sorting.field &&
      sorting.field != "numeroSurtido" &&
      sorting.field != "costo" &&
      sorting.field != "venta" &&
      sorting.field != "gastos" &&
      sorting.field != "saldo" &&
      sorting.field != "flete" &&
      sorting.field != "utilidad"
    ) {
      const reversed = sorting.order === "asc" ? 1 : -1;
      computedComments = computedComments.sort(
        (a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field])
      );
    }

    if (
      sorting.field &&
      sorting.order === "asc" &&
      (sorting.field == "numeroSurtido" ||
        sorting.field == "costo" ||
        sorting.field == "venta" ||
        sorting.field == "gastos" ||
        sorting.field == "saldo" ||
        sorting.field == "flete" ||
        sorting.field == "utilidad")
    ) {
      computedComments = computedComments.sort((a, b) =>
        parseFloat(a[sorting.field]) > parseFloat(b[sorting.field]) ? 1 : -1
      );
    }

    if (
      sorting.field &&
      sorting.order === "desc" &&
      (sorting.field == "numeroSurtido" ||
        sorting.field == "costo" ||
        sorting.field == "venta" ||
        sorting.field == "gastos" ||
        sorting.field == "saldo" ||
        sorting.field == "flete" ||
        sorting.field == "utilidad")
    ) {
      computedComments = computedComments.sort((a, b) =>
        parseFloat(a[sorting.field]) < parseFloat(b[sorting.field]) ? 1 : -1
      );
    }

    //Current Page slice
    return computedComments.slice(
      (currentPage - 1) * ITEMS_PER_PAGE,
      (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
    );
  }, [
    comments,
    currentPage,
    search,
    sorting,
    selectedSociedad,
  ]);


  const headers2 = [
    { name: "Sociedad", field: "sociedad", sortable: false },
    { name: "Utilidad", field: "utilidad", sortable: false },
  ];
async function Siguiente() {
  let array = participaciones.map((a)=>{
    return{
      idSocio: a.socios[0]._id,
      socio: a.socios[0].name,
      participacion: a.participacion,
      utilidad: (totalUtilidad * a.participacion) / 100
    }
  }).filter(function (el) {
    return el != null;
  });
  let ventasSociedades = Object.values(array);

  setCommentsSociedad(ventasSociedades);
  setVista("Reparto");
}
console.log("hola", comments)
function saveRetiro() {
  if(totalUtilidad == 0){
    Swal.fire({
      title: "Error!",
      text: "Debes ingresar un importe",
      icon: "error",
      confirmButtonText: "Ok",
    });
    return;
  }
  Swal.fire({
    title: "Estas seguro?",
    text: "Se registrará el retiro",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Si, Retirar!",
  }).then((result) => {
    if (result.isConfirmed) {
      toggleProgreso();
      let totalMails = commentsSociedad.length + comments.length;

      axios
        .post(
          URL_POR_RETIRAR,
          {
            fecha: hoy,
            total: totalUtilidad,
            observaciones: "NA",
            moneda: "MXN",
            sociedades: selectedSociedad,
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        )
        .then((dataRetiro) => {
          commentsSociedad.map((a) => {
            axios
              .post(
                `${URL_POR_RETIRAR_SOCIOS}`,
                {
                  fecha: hoy,
                  socios: a.idSocio,
                  porRetirar: dataRetiro.data._id,
                  importe: ((totalUtilidad * a.participacion) / 100).toFixed(2),
                  participacion: a.participacion,
                  moneda:"MXN",
                  sociedades: selectedSociedad,
                },
                {
                  headers: {
                    Authorization: `Bearer: ${localStorage.getItem(
                      "app_token"
                    )}`,
                  },
                }
              )
              
              .then(() => {
                totalMails = totalMails - 1;
                if (totalMails == 0) {
                  Swal.fire("Good job!", "Creado con exito", "success");
                  setTimeout(() => {
                    window.location.reload();
                  }, 1000);
                }
              })
              .catch((error) => {
                Swal.fire({
                  icon: "error",
                  title: "Oops...",
                  text: "Something went wrong!",
                  // footer: `${error.response.data}`,
                });
                console.log(error);
              });

            //Termina el map
          });
        })
        .catch((error) => {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!",
            // footer: `${error.response.data}`,
          });
          console.log(error);
        });


        //  Map Surtidos
          comments.map((a) => {
            if(a.select == true){
              
            axios
              .patch(
                `${URL_SURTIDOS}SurtidoReparto/${a.idSurtido}`,
                {
                  reparto: "Si",
                },
                {
                  headers: {
                    Authorization: `Bearer: ${localStorage.getItem(
                      "app_token"
                    )}`,
                  },
                }
              )
              
              .then(() => {
                totalMails = totalMails - 1;
                if (totalMails == 0) {
                  Swal.fire("Good job!", "Creado con exito", "success");
                  setTimeout(() => {
                    window.location.reload();
                  }, 1000);
                }
              })
              .catch((error) => {
                Swal.fire({
                  icon: "error",
                  title: "Oops...",
                  text: "Something went wrong!",
                  // footer: `${error.response.data}`,
                });
                console.log(error);
              });

            }else{
              totalMails = totalMails - 1;
              if (totalMails == 0) {
                Swal.fire("Good job!", "Creado con exito", "success");
                setTimeout(() => {
                  window.location.reload();
                }, 1000);
              }
            }
            //Termina el map
          });
    }
  });
}



  let totalCostoTabla = 0;
  let totalVentaTabla = 0;
  let totalGastosTabla = 0;
  let totalSaldoTabla = 0;
  let totalFleteTabla = 0;
  let totalUtilidadTabla = 0;
  return (
    <>
      <Header />
      <br />
      <br />
      {user.menu_admin ? (
        vista == "Ventas" ? (
        <div className="card col-12">
          <Row>
            <Col md={8}>
              <Button
                size="sm"
                href="/MenuAdmin"
                className="btn btn-danger"
                id="botonListado"
              >
                Regresar
              </Button>
            </Col>
            <Col sm={4}>
              <ButtonGroup id="logoutBoton">
                <Button
                  size="sm"
                  className="btn"
                  color="primary"
                  onClick={Siguiente}
                >
                  Siguiente
                </Button>
              </ButtonGroup>
            </Col>
          </Row>
          
          <h3 align="center">Reparto Utilidad</h3>
          <div className="row">
            <div className="col-md-8">
              <Pagination
                total={totalItems}
                itemsPerPage={ITEMS_PER_PAGE}
                currentPage={currentPage}
                onPageChange={(page) => setCurrentPage(page)}
              />
            </div>
            <div className="col-md-4 d-flex flex-row-reverse">
              <h6>Total a Repartir: {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(totalUtilidad)}</h6>
            </div>
          </div>
          <Row>
            <Col md={2}>
              <Label>Sociedades</Label>
              <Input
                bsSize="sm"
                type="select"
                value={selectedSociedad}
                onChange={(e) => {
                  setSelectedSociedad(e.target.value);
                  setCurrentPage(1);
                }}
              >
                <option value="">Selecciona</option>
                {sociedades
                  .sort((a, b) =>
                    a.nombre > b.nombre ? 1 : -1
                  )
                  .map((a) => {
                    return <option value={a._id}>{a.nombre}</option>;
                  })}
              </Input>
            </Col>
            
          </Row>
          <br />
          <Table size="sm" striped borderless className="table-responsive-xl">
            <TableHeader
              headers={headers}
              onSorting={(field, order) => setSorting({ field, order })}
            />
            <tbody>
              {commentsData.map((a) => {
                totalCostoTabla = totalCostoTabla + a.costo;
                totalVentaTabla = totalVentaTabla + a.venta;
                totalGastosTabla = totalGastosTabla + a.gastos;
                totalSaldoTabla = totalSaldoTabla + a.saldo;
                totalFleteTabla = totalFleteTabla + a.flete;
                totalUtilidadTabla = totalUtilidadTabla + a.utilidad;
                  return (
                    <tr>
                      <td>{a.fecha}</td>
                      <td>{a.numeroSurtido}</td>
                      <td>{a.sociedad}</td>
                      <td>{a.cliente}</td>
                      <td>{new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(a.costo)}</td>
                      <td>{new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(a.venta)}</td>
                      <td>{new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(a.gastos)}</td>
                      <td>{new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(a.flete)}</td>
                      <td>{new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(a.utilidad)}</td>
                      <td>{new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(a.saldo)}</td>
                      <td>{new Intl.NumberFormat("en-US", {style: "percent", currency: "USD", maximumFractionDigits: 2 }).format(a.utilidad / a.costo)}</td>
                <td>
                <Input
                              type="checkbox"
                              onChange={e => {
                                let checked = e.target.checked;
                                setComments(
                                  comments.map(data => {
                                    if(a.id == data.id){
                                    data.select = checked;
                                    } 
                                    return data;
                                  })
                                );
                                // ActualizaTotales()
                              }}
                              checked={a.select}
                            />
                </td>
                    </tr>
                  );
              })}
              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td className="negrita" align="center">
                  TOTAL
                </td>
                <td className="negrita">
                  {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(totalCostoTabla)}
                </td>
                <td className="negrita">
                  {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(totalVentaTabla)}
                </td>
                <td className="negrita">
                  {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(totalGastosTabla)}
                </td>
                <td className="negrita">
                  {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(totalFleteTabla)}
                </td>
                <td className="negrita">
                  {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(totalUtilidadTabla)}
                </td>
                <td className="negrita">
                  {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(totalSaldoTabla)}
                </td>
                <td className="negrita">{new Intl.NumberFormat("en-US", {style: "percent", currency: "USD", maximumFractionDigits: 2 }).format(totalUtilidadTabla / totalCostoTabla)}</td>
                <td></td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td className="negrita" align="center">
                  TOTAL GENERAL
                </td>
                <td className="negrita">
                  {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(totalTotalCosto)}
                </td>
                <td className="negrita">
                  {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(totalTotalVenta)}
                </td>
                <td className="negrita">
                  {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(totalTotalGastos)}
                </td>
                <td className="negrita">
                  {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(totalTotalFlete)}
                </td>
                <td className="negrita">
                  {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(totalTotalUtilidad)}
                </td>
                <td className="negrita">
                  {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(totalTotalSaldo)}
                </td>
                <td className="negrita">{new Intl.NumberFormat("en-US", {style: "percent", currency: "USD", maximumFractionDigits: 2 }).format(totalTotalUtilidad / totalTotalCosto)}</td>
                <td></td>
              </tr>
            </tbody>
          </Table>
          <div className="col-md-6">
            <Pagination
              total={totalItems}
              itemsPerPage={ITEMS_PER_PAGE}
              currentPage={currentPage}
              onPageChange={(page) => setCurrentPage(page)}
            />
          </div>
        </div>
        ):(
          <div className="card container col-sm-6">
          <h3 align="center">Utilidad por Sociedad</h3>
          <br />
          <h4>Total a repartir {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(totalUtilidad)}</h4>
          <br />
          <Table size="sm" striped borderless className="table-responsive-xl">
            <TableHeader
              headers={headers2}
              onSorting={(field, order) => setSorting({ field, order })}
            />
            <tbody>
              {commentsSociedad.map((a) => {
                  return (
                    <tr>
                      <td>{a.socio}</td>
                      <td>{new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(a.utilidad)}</td>
                    </tr>
                  );
              })}
            </tbody>
          </Table>
          <br />
            <Row>
              <Button className="btn btn-success" onClick={saveRetiro}>
                {" "}
                Registrar
              </Button>

              <Button
                href="/PorRetirar"
                className="btn btn-danger"
                id="botonListado"
              >
                Regresar
              </Button>
            </Row>
          </div>
        )

      ) : undefined}
      <br />

      <Modal size="sm" isOpen={modalProgreso} toggle={toggleProgreso}>
            <ModalHeader toggle={toggleProgreso}>
              <h4>Progreso</h4>
            </ModalHeader>
            <ModalBody>
              Estamos enviando los correos, este proceso puede tardar varios
              minutos.
              <br />
              Por favor no cierre ni refresque su navegador.
              <br />
              <div className="divPadre2">
                <div className="divHijo2">
                  <Spinner
                    style={{
                      width: "3rem",
                      height: "3rem",
                      color: "#232B41",
                    }}
                  />
                  <Spinner
                    style={{
                      width: "3rem",
                      height: "3rem",
                      color: "#232B41",
                    }}
                  />
                  <Spinner
                    style={{
                      width: "3rem",
                      height: "3rem",
                      color: "#232B41",
                    }}
                  />
                </div>
              </div>
            </ModalBody>
          </Modal>
      {loader}
    </>
  );
}

export default RepartoUtilidades;
