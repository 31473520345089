import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import { Col, Row, Form, Button, FormGroup, Label, Input } from "reactstrap";
import axios from "axios";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import Header from "../../../layout/Header/Header";

import { v4 as uuidv4 } from "uuid";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
    },
  },
  button: {
    margin: theme.spacing(1),
  },
}));

function OrdenesCompraCreate() {
  const { user } = useContext(AuthContext);
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;
  const URL_ARTICULOS = process.env.REACT_APP_URL_ARTICULOS;
  const URL_PROVEEDORES = process.env.REACT_APP_URL_PROVEEDORES;
  const URL_ORDENES_COMPRA = process.env.REACT_APP_URL_ORDENES_COMPRA;
  const URL_ARTICULOS_OC = process.env.REACT_APP_URL_ARTICULOS_OC;
  const URL_SOCIEDADES = process.env.REACT_APP_URL_SOCIEDADES;

  const [articulos, setArticulos] = useState([]);
  const [sociedades, setSociedades] = useState([]);
  const [selectedSociedad, setSelectedSociedad] = useState("");
  const [proveedores, setProveedores] = useState([]);
  const [selectedProveedor, setSelectedProveedor] = useState("");

  const [fecha, setFecha] = useState("");
  const [plazo, setPlazo] = useState(0);
  const [fecha_compromiso, setFechaCompromiso] = useState("");
  const [fecha_puerto_est, setFechaPuertoEst] = useState("");
  const [fecha_entrega_est, setFechaEntregaEst] = useState("");
  const [observaciones, setObservaciones] = useState("NA");
  const [total_general, setTotalGeneral] = useState(0);
  const [total_kilos, setTotalKilos] = useState(0);
  const [total_metros, setTotalMetros] = useState(0);
  const classes = useStyles();
  const [inputFields, setInputFields] = useState([
    {
      id: uuidv4(),
      articulos: "",
      unidad: "",
      colores: "",
      arrayColoresArticulo:[],
      cantidad: 0,
      precio: 0,
      descuento: 0,
      total: 0,
    },
  ]);

  const [validaBoton, setValidaBoton] = useState(true);

  useEffect(() => {
    axios
      .get(URL_ARTICULOS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allArticulos = res.data;
        setArticulos(allArticulos);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(URL_SOCIEDADES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allSociedades = res.data;
        setSociedades(allSociedades);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(URL_PROVEEDORES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allProveedores = res.data;
        setProveedores(allProveedores);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const saveOrdenCompra = async (event) => {
    event.preventDefault();
    setValidaBoton(false);
    try {
      await axios
        .post(
          URL_ORDENES_COMPRA,
          {
            fecha,
            proveedores: selectedProveedor,
            plazo,
            fecha_compromiso,
            fecha_puerto_est: fecha_compromiso,
            fecha_entrega_est: fecha_compromiso,
            total_general,
            observaciones,
            total_kilos,
            total_metros,
            autorizado: "Si",
            tipo:"Nacional",
            noOrdenProveedor:"NA",
            tipoCambioEstimado:0,
            sociedades: selectedSociedad,
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        )
        .then((data) => {
          inputFields.map((a) => {
            axios.post(
              URL_ARTICULOS_OC,
              {
                ordenesCompra: data.data._id,
                articulos: a.articulos,
                colores: a.colores,
                cantidad: parseFloat(a.cantidad),
                recibido: 0,
                pendiente_recibir: parseFloat(a.cantidad),
                precio: parseFloat(a.precio),
                descuento: 0,
                cantidadPL: 0,
                total: parseFloat(a.total),
                embarcado:0,
                pendiente_embarcar:0,
                sociedades: selectedSociedad,
              },
              {
                headers: {
                  Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
                },
              }
            );
          });
          axios.post(
            URL_LOGS,
            {
              tipo: "Crear Orden de Compra",
              detalle: `${selectedProveedor} ${total_general}`,
              user: user.id,
            },
            {
              headers: {
                Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
              },
            }
          );
          Swal.fire("Good job!", "Creado con exito", `success`);
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        })
        .catch((error) => {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!",
            footer: `${error.response.data}`,
          });
          console.log(error);
          setValidaBoton(true);
        });
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
        footer: `${error.response.data}`,
      });
      console.log(error);
    }
  };
  const handleAddFields = () => {
    const ultimo = inputFields[inputFields.length-1];
    setInputFields([
      ...inputFields,
      {
        id: uuidv4(),
        articulos: ultimo.articulos,
        unidad: ultimo.unidad,
        colores: ultimo.colores,
        arrayColoresArticulo: ultimo.arrayColoresArticulo,
        cantidad: 0,
        precio: ultimo.precio,
        total: 0,
      },
    ]);
  };

  const handleRemoveFields = (id) => {
    const values = [...inputFields];
    values.splice(
      values.findIndex((value) => value.id === id),
      1
    );
    setInputFields(values);
    let mts = values.filter(e=>e.unidad == "Metros")
    let totalMts = mts.map((c) => parseFloat(c.cantidad));
    let TM = totalMts.reduce((t, total, index) => t + total, 0);
    setTotalMetros(TM);
    let KG = values.filter(e=>e.unidad == "Kilos")
    let totalKG = KG.map((c) => parseFloat(c.cantidad));
    let TK = totalKG.reduce((t, total, index) => t + total, 0);
    setTotalKilos(TK);
    let importes = values.map((c) => parseFloat(c.total));
    let TG = importes.reduce((t, total, index) => t + total, 0);
    setTotalGeneral(TG);
  };

  const handleChangeInput = (id, event) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        i[event.target.name] = event.target.value;
        i.total = i.cantidad * i.precio;
        // i.total = (totalParcial - (totalParcial * i.descuento) / 100).toFixed(2);
      }
      return i;
    });
    setInputFields(newInputFields);
    ActualizaTotales()
  };

  function BuscaArticulo(id, event){
    articulos.map((a)=>{
      if(a._id == event.target.value){
        let idArticulo = a._id
        let unidadArticulo = a.unidad
        let coloresArticulo = a.colores
        handleChangeInputArticulo(id, idArticulo, unidadArticulo, coloresArticulo)
      }
    })
  }

  const handleChangeInputArticulo = (id, idArticulo, unidadArticulo, coloresArticulo) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        i.articulos = idArticulo;
        i.unidad = unidadArticulo
        i.arrayColoresArticulo = coloresArticulo
        i.total = i.cantidad * i.precio;
        // i.total = (totalParcial - (totalParcial * i.descuento) / 100).toFixed(2);
      }
      return i;
    });
    setInputFields(newInputFields);
    ActualizaTotales()
  };


  function ActualizaTotales(){
        let mts = inputFields.filter(e=>e.unidad == "Metros")
        let totalMts = mts.map((c) => parseFloat(c.cantidad));
        let TM = totalMts.reduce((t, total, index) => t + total, 0);
        setTotalMetros(TM);
        let KG = inputFields.filter(e=>e.unidad == "Kilos")
        let totalKG = KG.map((c) => parseFloat(c.cantidad));
        let TK = totalKG.reduce((t, total, index) => t + total, 0);
        setTotalKilos(TK);
        let importes = inputFields.map((c) => parseFloat(c.total));
        let TG = importes.reduce((t, total, index) => t + total, 0);
        setTotalGeneral(TG);
  }


  return (
    <>
      <Header />
      <br />
      <br />
      {user.oc_create ? (
        <div className="card container col-12">
          <h3 align="center">Nueva Orden de Compra</h3>
          <Form onSubmit={saveOrdenCompra}>
            <Row>
              <Col md={3}>
                <Label>Fecha</Label>
                <Input
                  type="date"
                  placeholder="Fecha"
                  value={fecha}
                  required
                  onChange={(e) => {
                    setFecha(e.target.value);
                  }}
                />
              </Col>
              <Col md={3}>
                <Label className="mr-sm-2">Sociedad</Label>
                <Input
                  className="col-sm-12"
                  type="select"
                  value={selectedSociedad}
                  required
                  onChange={(e) => {
                    setSelectedSociedad(e.target.value);
                  }}
                >
                  <option value="0">Selecciona un Sociedad</option>
                  {sociedades
                    .sort((a, b) => (a.nombre > b.nombre ? 1 : -1))
                    .map((a) => {
                      return <option value={a._id}>{a.nombre}</option>;
                    })}
                </Input>
              </Col>
              <Col md={3}>
                <Label className="mr-sm-2">Proveedor</Label>
                <Input
                  className="col-sm-12"
                  type="select"
                  value={selectedProveedor}
                  required
                  onChange={(e) => {
                    setSelectedProveedor(e.target.value);
                  }}
                >
                  <option value="0">Selecciona un Proveedor</option>
                  {proveedores
                    .sort((a, b) => (a.razon_social > b.razon_social ? 1 : -1))
                    .map((a) => {
                      return <option value={a._id}>{a.razon_social}</option>;
                    })}
                </Input>
              </Col>
              <Col md={3}>
                <Label>Plazo</Label>
                <Input
                  type="text"
                  placeholder="Plazo"
                  value={plazo}
                  required
                  onChange={(e) => {
                    setPlazo(e.target.value);
                  }}
                />
              </Col>
            </Row>
            <Row>
              <Col md={3}>
                <Label>Fecha Compromiso</Label>
                <Input
                  type="date"
                  placeholder="Fecha Compromiso"
                  value={fecha_compromiso}
                  required
                  onChange={(e) => {
                    setFechaCompromiso(e.target.value);
                  }}
                />
              </Col>
              <Col md={6}>
                <Label for="exampleEmail" className="mr-sm-2">
                  Observaciones
                </Label>
                <Input
                  type="text"
                  placeholder="Observaciones"
                  value={observaciones}
                  required
                  onChange={(e) => {
                    setObservaciones(e.target.value);
                  }}
                />
              </Col>
              {/* <Col md={3}>
              <h4 id="logoutBoton">
              TOTALES {total_kilos} kg. / {total_metros} mts. / {"$" + new Intl.NumberFormat("en-US").format(total_general)}
              </h4>
              </Col> */}
            </Row>

            {/* Tabla Articulos */}

              <Row>
                <Col md={2}>
                  <Label className="mr-sm-2">Articulos</Label>
                </Col>
                <Col md={1}>
                  <Label className="mr-sm-2">Unidad</Label>
                </Col>
                <Col md={2}>
                  <Label className="mr-sm-2">Colores</Label>
                </Col>
                <Col md={1}>
                  <Label className="mr-sm-2">Cantidad</Label>
                </Col>
                <Col md={1}>
                  <Label className="mr-sm-2">Precio</Label>
                </Col>
                <Col md={2}>
                  <Label className="mr-sm-2">Total</Label>
                </Col>
              </Row>

              {inputFields.map((inputField) => (
                <div key={inputField.id}>
                  <Row>
                    <Col md={2}>
                      <Input
                        bsSize="sm"
                        name="articulos"
                        type="select"
                        value={inputField.articulos}
                        required
                        onChange={(event)=>{BuscaArticulo(inputField.id, event)}}
                      >
                        <option value="">Selecciona un Articulo</option>
                        {articulos
                          .sort((a, b) => (a.codigo > b.codigo ? 1 : -1))
                          .map((a) => {
                            return (
                              <option value={a._id}>
                                {a.codigo} / {a.nombre}
                              </option>
                            );
                          })}
                      </Input>
                    </Col>
                    <Col md={1}>
                        <Input
                         bsSize="sm"
                          name="unidad"
                          type="string"
                          value={inputField.unidad}
                          disabled
                        >
                        </Input>
                      </Col>
                    <Col md={2}>
                      <Input
                        bsSize="sm"
                        name="colores"
                        type="select"
                        value={inputField.colores}
                        required
                        onChange={(event) =>
                          handleChangeInput(inputField.id, event)
                        }
                      >
                        <option value="">Selecciona un Color</option>
                        {inputField.arrayColoresArticulo
                          .sort((a, b) => (a.name > b.name ? 1 : -1))
                          .map((a) => {
                            return <option value={a._id}>{a.name}</option>;
                          })}
                      </Input>
                    </Col>

                    <Col md={1}>
                      <Input
                        bsSize="sm"
                        name="cantidad"
                        type="number"
                        placeholder="Cantidad"
                        value={inputField.cantidad}
                        required
                        onChange={(event) =>
                          handleChangeInput(inputField.id, event)
                        }
                      />
                    </Col>

                    <Col md={1}>
                      <Input
                        bsSize="sm"
                        name="precio"
                        type="number"
                        placeholder="Precio"
                        value={inputField.precio}
                        required
                        onChange={(event) =>
                          handleChangeInput(inputField.id, event)
                        }
                      />
                    </Col>

                    <Col md={2}>
                      <Input
                        bsSize="sm"
                        name="total"
                        type="number"
                        placeholder="Total"
                        value={inputField.total}
                        disabled
                        onChange={(event) =>
                          handleChangeInput(inputField.id, event)
                        }
                      />
                    </Col>
                    <Col>
                      <Button
                        size="sm"
                        className="btn"
                        color="danger"
                        disabled={inputFields.length === 1}
                        onClick={() => handleRemoveFields(inputField.id)}
                        tabindex="-1"
                      >
                        <i class="fas fa-minus"></i>
                      </Button>
                      <Button 
                      size="sm"
                      className="btn"
                      color="info"
                      onClick={handleAddFields}
                      tabindex="-1">
                      <i class="fas fa-plus"></i>
                      </Button>
                    </Col>
                  </Row>
                </div>
              ))}
              <Row>
              <Col md={10}>
              <h4 id="logoutBoton">
              TOTALES {total_kilos} kg. / {total_metros} mts. / {"$" + new Intl.NumberFormat("en-US").format(total_general)}
              </h4>
              </Col>
              </Row>
            {/* Termina Tabla Articulos */}

            <br />
            {validaBoton ? (
              <Button type="submit" className="btn btn-success">
                Guardar
              </Button>
            ) : (
              <Button type="submit" className="btn btn-success" disabled>
                Guardar
              </Button>
            )}
            <Button
              href="/ListadoOrdenesCompra"
              className="btn btn-danger"
              id="botonListado"
            >
              Regresar
            </Button>
          </Form>
          <br />
        </div>
      ) : undefined}
    </>
  );
}

export default OrdenesCompraCreate;
